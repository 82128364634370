<script>
import { Bar } from 'vue-chartjs';

export default {
  name: "BarChartAll",
  extends: Bar,
  data() {
    return {
      data: {
        labels: ['2015/08', '2016/08', '2017/08', '2018/08', '2019/08', '2020/11', '2021/08', '2022/08'],
        datasets: [
          {
            label: '合格者数',
            data: [1051, 1108, 1231, 1305, 1337, 1335, 1360, 1456],
            backgroundColor: [
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
            ],
            borderColor: [
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
            ],
            borderWidth: 1,
            yAxisID: "y-axis-1"
          },
          {
            label: '合格率',
            data: [10.3, 10.8, 11.2, 11.1, 10.7, 10.1, 9.6, 7.7],
            borderColor: '#CFD8DC',
            fill: false,
            type: 'line',
            lineTension: 0.4,
            yAxisID: "y-axis-2"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: '各回数'
            }
          }],
          yAxes: [{
            id: "y-axis-1",
            ticks: {
              max: 1500,
              min: 0,
              beginAtZero: true,
              stepSize: 100,
            }
          },
            {
              id: "y-axis-2",
              position: 'right',
              ticks: {
                max: 15,
                min: 0,
                beginAtZero: true,
                stepSize: 5,
              }
            }]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.data, this.options)
  }
}
</script>

<style scoped>

</style>

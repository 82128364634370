<template>
  <v-card class="mt-5 pa-1">
    <v-card-title class="font-weight-bold">
      短答式試験の合格者数とその推移
    </v-card-title>
    <bar-chart-tantou />
    <v-btn
      class="d-none d-sm-flex"
      block="block"
      elevation="2"
      color="rgba(75, 192, 192, 0.1)"
      @click="expand = !expand"
    >
      データテーブルを見る
    </v-btn>
    <v-expand-transition>
      <v-card v-show="expand">
        <v-text-field
          v-model="search"
          class="offset-6"
          append-icon="mdi-magnify"
          label="Search"
          single-line="single-line"
          hide-details="hide-details"
        />
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          hide-default-footer="hide-default-footer"
        />
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import BarChartTantou from "./charts/BarChartTantou";

export default {
  components: { BarChartTantou },
  data() {
    return {
      expand: false,
      search: '',
      headers: [
        {
          text: '試験年度',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: '(a)願書提出者数(人)', value: 'gansyo' },
        { text: '(b)答案提出者数(人)', value: 'touan' },
        { text: '(c)合格者数(人)', value: 'goukaku' },
        { text: '(c)/(a)対願書提出者合格率(%)', value: 'ggoukakuritsu' },
        { text: '(c)/(b)対答案提出者合格率(%)', value: 'tgoukakuritsu' },
      ],
      desserts: [
        {
          name: '2015/12',
          gansyo: 7030,
          touan: 5479,
          goukaku: 863,
          ggoukakuritsu: 12.3,
          tgoukakuritsu: 15.8,
        },
        {
          name: '2016/05',
          gansyo: 7968,
          touan: 4740,
          goukaku: 638,
          ggoukakuritsu: 8.0,
          tgoukakuritsu: 13.5,
        },
        {
          name: '2016/12',
          gansyo: 7818,
          touan: 6045,
          goukaku: 1194,
          ggoukakuritsu: 15.3,
          tgoukakuritsu: 19.8,
        },
        {
          name: '2017/05',
          gansyo: 8214,
          touan: 4916,
          goukaku: 1194,
          ggoukakuritsu: 5.8,
          tgoukakuritsu: 9.7,
        },
        {
          name: '2017/12',
          gansyo: 8373,
          touan: 6569,
          goukaku: 475,
          ggoukakuritsu: 13.0,
          tgoukakuritsu: 16.6,
        },
        {
          name: '2018/05',
          gansyo: 8793,
          touan: 5346,
          goukaku: 975,
          ggoukakuritsu: 11.1,
          tgoukakuritsu: 18.2,
        },
        {
          name: '2018/12',
          gansyo: 8515,
          touan: 6610,
          goukaku: 1097,
          ggoukakuritsu: 12.9,
          tgoukakuritsu: 16.6,
        },
        {
          name: '2019/05',
          gansyo: 9531,
          touan: 5604,
          goukaku: 709,
          ggoukakuritsu: 7.4,
          tgoukakuritsu: 12.7,
        },
        {
          name: '2019/12',
          gansyo: 9393,
          touan: 7245,
          goukaku: 1139,
          ggoukakuritsu: 12.1,
          tgoukakuritsu: 15.7,
        },
        {
          name: '2020/08',
          gansyo: 9383,
          touan: 5616,
          goukaku: 722,
          ggoukakuritsu: 7.7,
          tgoukakuritsu: 12.9,
        },
        {
          name: '2021/05',
          gansyo: 14192,
          touan: 9524,
          goukaku: 2060,
          ggoukakuritsu: 14.5,
          tgoukakuritsu: 21.6,
        },
        {
          name: '2021/12',
          gansyo: 12719,
          touan: 9949,
          goukaku: 1199,
          ggoukakuritsu: 9.4,
          tgoukakuritsu: 12.1,
        },
        {
          name: '2022/05',
          gansyo: 14958,
          touan: 9870,
          goukaku: 780,
          ggoukakuritsu: 5.2,
          tgoukakuritsu: 7.9,
        },
        {
          name: '2022/12',
          gansyo: 14550,
          touan: 11401,
          goukaku: 1182,
          ggoukakuritsu: 8.1,
          tgoukakuritsu: 10.4,
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>

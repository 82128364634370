<template>
  <v-card class="mt-5">
    <v-card-title class="font-weight-bold">
      短答式試験各科目の平均点
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line="single-line"
        hide-details="hide-details"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="tantouave"
      :search="search"
      hide-default-footer="hide-default-footer"
    />
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: '試験年度',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: '総合(%)', value: 'sougou' },
        { text: '財務会計論(%)', value: 'zaimu' },
        { text: '管理会計論(%)', value: 'kanri' },
        { text: '監査論(%)', value: 'kansa' },
        { text: '企業法(%)', value: 'kigyo' },
      ],
      tantouave: [
        {
          name: '2015/12',
          sougou: 48,
          zaimu: 50,
          kanri: 46,
          kansa: 46,
          kigyo: 47,
        },
        {
          name: '2016/05',
          sougou: 46,
          zaimu: 44,
          kanri: 48,
          kansa: 48,
          kigyo: 46,
        },
        {
          name: '2016/12',
          sougou: 52,
          zaimu: 44,
          kanri: 51,
          kansa: 55,
          kigyo: 61,
        },
        {
          name: '2017/05',
          sougou: 44,
          zaimu: 33,
          kanri: 47,
          kansa: 50,
          kigyo: 52,
        },
        {
          name: '2017/12',
          sougou: 50,
          zaimu: 45,
          kanri: 49,
          kansa: 58,
          kigyo: 51,
        },
        {
          name: '2018/05',
          sougou: 46,
          zaimu: 40,
          kanri: 46,
          kansa: 54,
          kigyo: 49,
        },
        {
          name: '2018/12',
          sougou: 44,
          zaimu: 38,
          kanri: 44,
          kansa: 54,
          kigyo: 47,
        },
        {
          name: '2019/05',
          sougou: 43,
          zaimu: 43,
          kanri: 37,
          kansa: 43,
          kigyo: 46,
        },
        {
          name: '2019/12',
          sougou: 39,
          zaimu: 34,
          kanri: 35,
          kansa: 48,
          kigyo: 44,
        },
        {
          name: '2020/08',
          sougou: 46.1,
          zaimu: 43.7,
          kanri: 46.0,
          kansa: 52.2,
          kigyo: 43.5,
        },
        {
          name: '2021/05',
          sougou: 47.3,
          zaimu: 47.0,
          kanri: 40.9,
          kansa: 52.3,
          kigyo: 47.8,
        },
        {
          name: '2021/12',
          sougou: 47.5,
          zaimu: 44.1,
          kanri: 47.1,
          kansa: 53.9,
          kigyo: 46.1,
        },
        {
          name: '2022/05',
          sougou: 47.5,
          zaimu: 46.5,
          kanri: 48.7,
          kansa: 52.2,
          kigyo: 43.4,
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>

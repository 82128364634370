<script>
import { Bar } from 'vue-chartjs';

export default {
  name: "BarChartTantou",
  extends: Bar,
  data() {
    return {
      data: {
        labels: ['2015/12', '2016/05', '2016/12', '2017/05', '2017/12', '2018/05', '2018/12', '2019/05', '2019/12', '2020/08', '2021/05', '2021/12', '2022/05', '2022/12'],
        datasets: [
          {
            label: '合格者数',
            data: [863, 638, 1194, 475, 1090, 975, 1097, 709, 1139, 722, 2060, 1199, 780, 1182],
            backgroundColor: [
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
              'rgba(75, 192, 192, 0.1)',
            ],
            borderColor: [
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
              'rgba(0, 102, 0, 1)',
            ],
            borderWidth: 1,
            yAxisID: "y-axis-1"
          },
          {
            label: '合格率',
            data: [12.3, 8, 15.3, 5.8, 13.0, 11.1, 12.9, 7.4, 12.1, 7.7, 14.5, 9.4, 5.2, 10.4],
            borderColor: '#CFD8DC',
            fill: false,
            type: 'line',
            lineTension: 0.4,
            yAxisID: "y-axis-2"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: '各回数'
            },
            gridLines: {
              display: false
            },
          }],
          yAxes: [{
            id: "y-axis-1",
            ticks: {
              min: 0,
              beginAtZero: true,
              stepSize: 100,
            },
          },
            {
              id: "y-axis-2",
              position: 'right',
              ticks: {
                max: 20,
                min: 0,
                beginAtZero: true,
                stepSize: 5,
              },
            }]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.data, this.options)
  }
}
</script>

<style scoped>

</style>

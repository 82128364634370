<template>
  <v-card class="mt-5 pa-1">
    <v-card-title class="font-weight-bold">
      会計士試験の合格者数とその推移(全体)
    </v-card-title>
    <bar-chart-all />
    <v-btn
      class="d-none d-sm-flex"
      block="block"
      elevation="2"
      color="rgba(75, 192, 192, 0.1)"
      @click="expand = !expand"
    >
      データテーブルを見る
    </v-btn>
    <v-expand-transition>
      <v-card v-show="expand">
        <v-text-field
          v-model="search"
          class="offset-6"
          append-icon="mdi-magnify"
          label="Search"
          single-line="single-line"
          hide-details="hide-details"
        />
        <v-data-table
          :headers="headers"
          :items="all"
          :search="search"
          hide-default-footer="hide-default-footer"
        />
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import BarChartAll from "./charts/BarChartAll";
export default {
  components: { BarChartAll },
  data() {
    return {
      expand: false,
      search: '',
      headers: [
        {
          text: '試験年度',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: '(a)願書提出者数(人)', value: 'igansyo' },
        { text: '短答式試験受験者数(人)', value: 'tantojuken' },
        { text: '短答式合格者数(人)', value: 'tgoukakusya' },
        { text: '論文式試験受験者数(人)', value: 'rjuken' },
        { text: '(b)最終合格者数(人)', value: 'fgokakusya' },
        { text: '(b/a)合格率(%)', value: 'fgokaku' },
      ],
      all: [
        {
          name: '2015/08',
          igansyo: 10180,
          tantojuken: 8620,
          tgoukakusya: 1507,
          rjuken: 3086,
          fgokakusya: 1051,
          fgokaku: 10.3,
        },
        {
          name: '2016/08',
          igansyo: 10256,
          tantojuken: 8644,
          tgoukakusya: 1501,
          rjuken: 3138,
          fgokakusya: 1108,
          fgokaku: 10.8,
        },
        {
          name: '2017/08',
          igansyo: 11032,
          tantojuken: 9416,
          tgoukakusya: 1669,
          rjuken: 3306,
          fgokakusya: 1231,
          fgokaku: 11.2,
        },
        {
          name: '2018/08',
          igansyo: 11742,
          tantojuken: 10153,
          tgoukakusya: 2065,
          rjuken: 3678,
          fgokakusya: 1305,
          fgokaku: 11.1,
        },
        {
          name: '2019/08',
          igansyo: 12532,
          tantojuken: 10563,
          tgoukakusya: 1806,
          rjuken: 3792,
          fgokakusya: 1337,
          fgokaku: 10.7,
        },
        {
          name: '2020/11',
          igansyo: 13231,
          tantojuken: 11598,
          tgoukakusya: 1861,
          rjuken: 3719,
          fgokakusya: 1335,
          fgokaku: 10.1,
        },
        {
          name: '2021/08',
          igansyo: 14192,
          tantojuken: 12260,
          tgoukakusya: 2060,
          rjuken: 3992,
          fgokakusya: 1360,
          fgokaku: 9.6,
        },
        {
          name: '2022/08',
          igansyo: 18789,
          tantojuken: 16701,
          tgoukakusya: 1979,
          rjuken: 4067,
          fgokakusya: 1456,
          fgokaku: 7.7,
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>

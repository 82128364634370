<template>
  <v-card class="mt-5 pa-1">
    <v-card-title class="font-weight-bold">
      論文式の合格者数とその推移
    </v-card-title>
    <bar-chart-ronbun />
    <v-btn
      class="d-none d-sm-flex"
      block="block"
      elevation="2"
      color="rgba(75, 192, 192, 0.1)"
      @click="expand = !expand"
    >
      データテーブルを見る
    </v-btn>
    <v-expand-transition>
      <v-card v-show="expand">
        <v-text-field
          v-model="search"
          class="offset-6"
          append-icon="mdi-magnify"
          label="Search"
          single-line="single-line"
        />
        <v-data-table
          :headers="headers"
          :items="ronbun"
          :search="search"
          hide-default-footer="hide-default-footer"
        />
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
  import BarChartRonbun from "./charts/BarChartRonbun";

  export default {
    components: { BarChartRonbun },
    data() {
      return {
        expand: false,
        search: '',
        headers: [
          {
            text: '試験年度',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: '(a)願書提出者数(人)', value: 'rgansyo' },
          { text: '(b)答案提出者数(人)', value: 'rtouan' },
          { text: '(c)合格者数(人)', value: 'goukaku' },
          { text: '(c)/(a)対願書提出者合格率(%)', value: 'rggokaku' },
          { text: '(c)/(b)対答案提出者合格率(%)', value: 'tggokaku' },
        ],
        ronbun: [
          {
            name: '2015/08',
            rgansyo: 3086,
            rtouan: 2759,
            goukaku: 1051,
            rggokaku: 34.1,
            tggokaku: 38.1,
          },
          {
            name: '2016/08',
            rgansyo: 3138,
            rtouan: 2790,
            goukaku: 1108,
            rggokaku: 35.3,
            tggokaku: 39.7,
          },
          {
            name: '2017/08',
            rgansyo: 3306,
            rtouan: 2971,
            goukaku: 1231,
            rggokaku: 37.2,
            tggokaku: 41.4,
          },
          {
            name: '2018/08',
            rgansyo: 3678,
            rtouan: 3312,
            goukaku: 1305,
            rggokaku: 35.5,
            tggokaku: 39.4,
          },
          {
            name: '2019/08',
            rgansyo: 3792,
            rtouan: 3397,
            goukaku: 1337,
            rggokaku: 35.3,
            tggokaku: 39.4,
          },
          {
            name: '2020/08',
            rgansyo: 3791,
            rtouan: '-',
            goukaku: 1335,
            rggokaku: 35.2,
            tggokaku: '-',
          },
          {
            name: '2021/08',
            rgansyo: 3992,
            rtouan: '-',
            goukaku: 1360,
            rggokaku: 35.3,
            tggokaku: '-',
          },
                    {
            name: '2022/08',
            rgansyo: 4067,
            rtouan: '-',
            goukaku: 1456,
            rggokaku: 35.8,
            tggokaku: '-',
          },
        ],
      }
    },
  }
</script>

<style scoped>

</style>

<template>
  <v-card>
    <v-row class="justify-space-between">
      <v-img
        class="mt-3 mx-10"
        src="@/assets/Statistics.png"
        eager="eager"
        contain="contain"
        max-width="700px"
        width="100px"
      />
    </v-row>
    <Statisticsall />
    <v-divider />
    <Statisticstantou />
    <v-divider />
    <Statisticsronbun />
    <div class="hidden-xs-only">
      <v-divider />
      <TantouAverage />
    </div>
  </v-card>
</template>


<script>
import Statisticsall from "../components/Statistics/Statisticsall";
import Statisticsronbun from "../components/Statistics/Statisticsronbun";
import Statisticstantou from "../components/Statistics/Statisticstantou";
import TantouAverage from "../components/Statistics/TantouAverage";

export default {
  name: "Statistics",
  components: { Statisticsall, Statisticsronbun, Statisticstantou, TantouAverage },
  data() {
    return {}
  }
}

</script>

<style scoped>

</style>
